import nextButtonImage from '@assets/icons/nextButtonImage.svg';
import prevButtonImage from '@assets/icons/prevButtonImage.svg';
import styled from 'styled-components';
import SwiperCore from 'swiper';
import { SwiperOptions } from 'swiper/types';
import { Swiper } from 'swiper/react';

export interface StyledSwiperProps extends SwiperOptions {
    swipertranslate: number;
}
export const Container = styled.div`
    position: relative;
    align-self: self-start;
    padding: 0 1.8vw;

    opacity: 1;
    visibility: visible;
    transition:
        opacity 0.5s ease-in-out,
        visibility 0s linear 0.5s;

    &.hidden {
        opacity: 0;
        visibility: hidden;
        transition:
            opacity 0.5s ease-in-out,
            visibility 0s linear 0.5s; // Ensures visibility transitions after opacity
    }
`;

export const StyledButtonSwiper = styled.div.attrs<{ swiperInstance?: SwiperCore | null }>(() => ({
    swiperInstance: undefined,
}))`
    position: absolute;
    top: 50%;
    left: 50%;
    display: contents;
    transform: translate(-50%, -50%);
    z-index: 2;

    .swiper-button-prev {
        display: ${({ swiperInstance }) => (swiperInstance && !swiperInstance.isBeginning ? 'none' : 'flex')};
        ${props => props.theme.devices.mobile} {
            display: none;
        }
        width: ${props => props.theme.globalSize.size2};
        height: ${props => props.theme.globalSize.size2};
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.primary};
        border-radius: 50%;
        justify-content: center;
        border: 2px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
        align-items: center;
        &:after {
            font-size: x-small;
            position: absolute;
            font-weight: bolder;
            content: url(${prevButtonImage});
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0;
        }
    }

    .swiper-button-next {
        display: ${({ swiperInstance }) => (!swiperInstance || swiperInstance.slides.length === -1 || swiperInstance.isBeginning ? 'flex' : 'none')};
        ${props => props.theme.devices.mobile} {
            display: none;
        }
        width: ${props => props.theme.globalSize.size2};
        height: ${props => props.theme.globalSize.size2};
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.primary};
        border-radius: 50%;
        justify-content: center;
        border: 2px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
        align-items: center;
        &:after {
            font-size: x-small;
            position: absolute;
            font-weight: bolder;
            content: url(${nextButtonImage});
        }
        &:disabled {
            cursor: not-allowed;
            opacity: 0;
        }
    }
` as React.FC<React.HTMLAttributes<HTMLDivElement> & { swiperInstance?: SwiperCore | null }>;

export const StyledSwiper = styled(Swiper)<StyledSwiperProps>`
    width: 95%;
    padding-top: 7% !important;

    ${props => props.theme.devices.mobile} {
        padding-top: 10%;
        width: 100%;
        transform: translate3d(0px, 0, 0);
        transition: transform 0.1s ease;
    }
`;

export const SlidesStyled = styled.div`
        .product-image {
            height: 100%;
            text-align: -webkit-center;
            z-index: 1;

            > img {      
                margin: auto;
                box-shadow: 0px 6px 17px 0px #4d4d4d47;
            }
     
        }
    }
    &::-webkit-scrollbar {
        ${props => props.theme.devices.mobile} {
            display: none;
        }
    }
`;

export const ProductName = styled.div`
    color: ${props => props.theme.colors.greyDark};
    text-align: center;
    ${props => props.theme.devices.mobile} {
        font-weight: 400;
        font-size: ${props => props.theme.globalSize.size5};
        line-height: 130%;
        padding-top: 18px;
    }

    ${props => props.theme.devices.desktop} {
        font-weight: 500;
        font-size: ${props => props.theme.globalSize.mobileSize3};
        font-family: ${props => props.theme.fonts.familySecondary};
        font-style: normal;
        line-height: 150%;
        text-align: center;
    }
`;
